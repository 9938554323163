<template>
  <div class="el-pag-container flex flex-center flex-middle">
    <el-pagination
      :page-size="pageSize"
      layout="total, prev, pager, next"
      background
      :total="totalPage"
      :current-page="currentPage"
      :page-sizes="[10, 25, 50, 100]"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      data-testid="pagination"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: ['tableData', 'totalRecords'],
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      displayData: []
    };
  },
  computed: {
    totalPage() {
      return this.totalRecords;
    }
  },
  watch: {
    tableData(val) {
      this.updateData();
    }
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber;
      this.$emit('current-page', pageNumber);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.updateData();
    },
    updateData() {
      this.displayData = this.tableData;
      this.$emit('update:display-data', this.displayData);
    },
    resetPage() {
      this.currentPage = 1;
      this.updateData();
    }
  }
};
</script>
<style lang="scss" scoped></style>
