<template>
  <el-dropdown placement="top-start" class="tree_container">
    <span class="el-dropdown-link">
      {{ displaySubIbAccountInfo }}
      <i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown" class="tree_dropdown">
      <el-input
        prefix-icon="el-icon-search"
        :placeholder="$t('report.rebate.others.searchBy')"
        v-model="filterText"
        class="tree_input"
      >
      </el-input>
      <!-- @current-change="checkchange" @check-change="checkchange" -->
      <el-tree
        :data="data"
        @current-change="checkchange"
        :highlight-current="true"
        :default-expanded-keys="[2, 3]"
        :props="defaultProps"
        :filter-node-method="filterNode"
        :check-on-click-node="true"
        ref="tree"
      >
      </el-tree>
      <el-button class="ib-primary-button w-100" plain @click="resetSubIbAccountInfo">
        {{ $t('report.trade.clear') }}
      </el-button>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { apiRebatetreeData } from '@/resource';
export default {
  props: {
    accountID: String | Number,
    chooseNode: {
      type: Object,
      default: function () {
        return {
          name: this.$t('common.selectIb')
        };
      }
    }
  },
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: data => {
          return `${data.data.name} (${data.data.mt4Account})`;
        }
      },
      filterText: '',
      defaultSubIbAccountInfoText: this.$t('common.selectIb'),
      displaySubIbAccountInfo: this.$t('common.selectIb')
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    accountID() {
      this.resetSubIbAccountInfo();
      this.getRebatetreeData();
    }
  },
  methods: {
    checkchange(res) {
      this.$emit('update:chooseNode', res.data);

      if (res?.data?.name && res?.data?.mt4Account) {
        this.displaySubIbAccountInfo = res.data.name + ' (' + res.data.mt4Account + ')';
      } else {
        this.displaySubIbAccountInfo = this.defaultSubIbAccountInfoText;
      }
      document.getElementsByClassName('tree_dropdown')[0].style.display = 'none';
    },
    async getRebatetreeData() {
      console.log(this.accountID);
      let res = await apiRebatetreeData(this.accountID);
      console.log(res.data, 'dddd');
      this.data = res?.data?.children || [];
      //   {
      //       mt4Account: 1100000072,
      //       accountType: 3,
      //       name: null,
      //       userId: null,
      //       dataSourceId: null,
      //       archived: null
      //     },
      //     children: [
      //       {
      //         data: {
      //           mt4Account: 11004002,
      //           accountType: 3,
      //           name: 'KoreaRepublic test',
      //           userId: 10009083,
      //           dataSourceId: 302,
      //           archived: false
      //         },
      //         children: []
      //       }
      //     ]
      //   }
      // ];
    },
    handleNodeClick(data) {
      console.log(data);
    },
    filterNode(value, data) {
      console.log(value, data, '5');
      if (!value) return true;
      let a = value.toLowerCase();
      let name = data?.data?.name.toLowerCase();
      let num = (data?.data?.mt4Account + '').toLowerCase();
      return name.indexOf(a) !== -1 || num.indexOf(a) !== -1;
    },
    resetSubIbAccountInfo() {
      this.$emit('update:chooseNode', {});
      this.displaySubIbAccountInfo = this.defaultSubIbAccountInfoText;
    }
  },
  mounted() {
    this.getRebatetreeData();
  }
};
</script>

<style lang="scss" scoped>
.tree_container {
  flex: 1;
  height: 45px !important;
  background: $black2;
  cursor: pointer;
  /deep/ .el-dropdown-link {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 14px;
    color: $white;
  }
}
.tree_dropdown {
  padding: 15px;
  box-sizing: border-box;
  /deep/ .el-tree {
    width: 350px;
    height: 300px;
    overflow: scroll;
    .el-tree-node__children {
      overflow: unset;
    }
  }
  .tree_input {
    height: 28px;
    margin-bottom: 10px;
    /deep/ .el-input__inner {
      height: 28px;
      background: #f3f3f3;
      border-radius: 4px;
      border: 0;
      line-height: 28px;
      font-size: 14px;
      color: $black;
      padding-left: 30px;

      @include placeholder {
        color: $stone-gray;
        font-weight: 400;
        font-size: 14px;
      }
    }
    /deep/ .el-icon-search {
      line-height: 28px;
      font-size: 16px;
      color: #3d3d3d;
    }
  }
}
</style>
