<template>
  <div id="rebate_report">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="title">{{ $t('menu.tradeReport') }}</div>
        <div class="query">
          <div class="flex top">
            <AccountNumber @accountCallback="init"></AccountNumber>
            <SelectTree :accountID="accountID" :chooseNode.sync="chooseNode" />
            <el-cascader
              expand-trigger="hover"
              :options="instrumentOptions"
              v-model="selectedInstrument"
              :show-all-levels="false"
            ></el-cascader>
          </div>
          <div class="flex bottom flex-center select_lbox">
            <DateRangePicker
              v-bind:startDate.sync="startDate"
              v-bind:endDate.sync="endDate"
              :threeMonthsRange="true"
            ></DateRangePicker>
            <loading-button class="ib-button" :callback="queryTradeReport" text="common.keys.UPDATE"></loading-button>
          </div>
        </div>
        <div class="content">
          <div class="select download_container item_header">
            <span>
              {{ $t('report.rebate.others.rebate') }} {{ $t('common.keys.FROM') }}
              {{ startDate | formatDate('DD/MM/YYYY') }} {{ $t('common.keys.TO') }}
              {{ endDate | formatDate('DD/MM/YYYY') }}
            </span>
            <div>
              <el-tooltip
                class="item"
                v-if="progressBarLoading"
                effect="dark"
                :content="loadingMessage"
                placement="top"
                :value="true"
                :manual="true"
              >
                <el-progress
                  type="circle"
                  :show-text="false"
                  :percentage="progressBarPercentage"
                  :width="20"
                  color="#ffcd05"
                ></el-progress>
              </el-tooltip>

              <loading-button class="ib-button" :callback="downLoad" text="common.keys.DOWNLOAD"></loading-button>
            </div>
          </div>
          <div class="table_box">
            <el-table
              :data="displayData"
              ref="summaryTable"
              style="width: 100%"
              :default-sort="{ prop: 'profit', order: 'descending' }"
              @sort-change="sortChange"
            >
              <el-table-column :label="$t('report.trade.column.account')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.account }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('report.trade.column.ticket')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.ticket }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('report.trade.column.platform')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.platform }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('report.trade.column.symbol')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.symbol }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('report.rebate.column.volume')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.volume | formatNumber }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('report.trade.column.type')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.transactionTypeDesc }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('report.trade.column.closeTime')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.closeTime }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('report.trade.column.profit')"
                prop="profit"
                sortable
                sort-by="profit"
                :sort-method="profitSorting"
                :sort-orders="['ascending', 'descending']"
                min-width="100"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.profit | formatNumberWithNeg }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('report.trade.column.commission')"
                prop="commission"
                sortable
                sort-by="commission"
                :sort-orders="['ascending', 'descending']"
                min-width="100"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.commission | formatNumber(4) }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <pagination
            @current-page="updateRebateReport"
            v-bind:table-data="tableData"
            v-bind:display-data.sync="displayData"
            v-bind:total-records="totalRecords"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/PaginationAsync';
import LoadingButton from '@/components/LoadingButton';
import DateRangePicker from '@/components/DateRangePicker';
import AccountNumber from '@/components/form/AccountNumber';
import { apiTrade_report, apiRebate_report, apiGet_instruments } from '@/resource';
import moment from 'moment';
import { Message } from 'element-ui';
import ExportJsonExcel from 'js-export-excel';
import SelectTree from '@/components/SelectTree';
export default {
  components: { Pagination, LoadingButton, DateRangePicker, AccountNumber, SelectTree },
  data() {
    return {
      startDate: '',
      endDate: '',
      //可能存在修改时间，却没有点刷新的情况，所以要记录一下刷新时间。
      updateTimeRecord: { startDate: this.startDate, endDate: this.endDate },
      userID: this.$store.state.common.CUID,
      selectedInstrument: [null],
      instrumentOptions: [
        {
          value: null,
          label: this.$t('report.rebate.dropdown.allInstruments')
        },
        {
          value: 'forex',
          label: this.$t('common.products.forex'),
          children: []
        },
        {
          value: 'commodities',
          label: this.$t('common.products.commodities'),
          children: []
        },
        {
          value: 'indices',
          label: this.$t('common.products.indices'),
          children: []
        },
        {
          value: 'crypto',
          label: this.$t('common.products.crypto'),
          children: []
        },
        {
          value: 'sharecfd',
          label: this.$t('common.products.sharecfd'),
          children: []
        },
        {
          value: 'bond',
          label: this.$t('common.products.bond'),
          children: []
        }
      ],
      // 分页
      displayData: [],
      sorting: { column: 'profit', order: 'descending' },
      tableData: [],
      chooseNode: null,
      theCurrentPage: 1,
      totalRecords: 10,
      progressBarPercentage: 0,
      progressBarLoading: false,
      progressBarGain: 0
    };
  },
  watch: {
    dataSourceId() {
      this.queryInstruments();
    }
  },
  methods: {
    async downLoad() {
      //没有数据是下载功能return
      if (!this.tableData.length) {
        Message({
          message: this.$t('report.nodataMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }

      const fileName = `rebate_report_${moment(this.updateTimeRecord.startDate).format('YYYY-MM-DD')}_${moment(
        this.updateTimeRecord.endDate
      ).format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD HH:mm:ss A')}`;
      this.progressBarLoading = true;
      this.progressBarPercentage = 0;

      //表头字段
      const tradeHeader = [
        'report.trade.column.account',
        'report.trade.column.ticket',
        'report.trade.column.platform',
        'report.trade.column.symbol',
        'report.rebate.column.volume',
        'report.trade.column.type',
        'report.trade.column.closeTime',
        'report.trade.column.profit',
        'report.trade.column.commission'
      ].map(key => this.$t(key));

      let tradeHistory = await this.downlaodingTradeReport();

      let option = {
        fileName,
        datas: [
          {
            sheetData: tradeHistory,
            sheetName: 'Trade Report',
            sheetHeader: tradeHeader,
            columnWidths: [8, 8, 6, 6, 6, 4, 8, 6, 8]
          }
        ]
      };
      const toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();

      this.progressBarLoading = false;
      return Promise.resolve();
    },
    //获取多列详情数据
    async queryAllTradeDetails(currentPage = 1, currentPageSize = 500) {
      return apiTrade_report({
        pageNo: currentPage,
        pageSize: currentPageSize,
        account: this.accountID,
        subRebateAccount: this.chooseNode?.mt4Account || '',
        startDate: this.startDate,
        endDate: this.endDate,
        symbol:
          Array.isArray(this.selectedInstrument) && this.selectedInstrument.length == 2
            ? this.selectedInstrument[1]
            : null
      }).then(response => {
        if (response.data?.rows) {
          this.progressBarPercentage =
            this.progressBarPercentage + this.progressBarGain > 100
              ? 100
              : this.progressBarPercentage + this.progressBarGain;

          return response.data.rows;
        }
      }, this.$handleError);
    },
    queryTradeReport(currentPage = 1, currentPageSize = 10) {
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }
      this.updateTimeRecord = {
        startDate: this.startDate,
        endDate: this.endDate
      };
      return apiTrade_report({
        pageNo: currentPage,
        pageSize: currentPageSize,
        account: this.accountID,
        subRebateAccount: this.chooseNode?.mt4Account || '',
        startDate: this.startDate,
        endDate: this.endDate,
        symbol:
          Array.isArray(this.selectedInstrument) && this.selectedInstrument.length == 2
            ? this.selectedInstrument[1]
            : null
      }).then(response => {
        if (response.data?.rows) {
          this.totalRecords = response.data.total;

          this.tableData = response.data.rows.map(item => {
            var objs = Object.keys(item).reduce((newData, key) => {
              let newKey = key;
              newData[newKey] = item[key];
              return newData;
            }, {});
            return objs;
          });

          this.sortData();
        }
      }, this.$handleError);
    },
    updateRebateReport(pageNumber) {
      this.queryTradeReport(pageNumber);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'profit';
      this.sorting.order = this.sorting.order || 'descending';
      this.tableData.sort((a1, a2) => {
        if (this.sorting.column == 'profit') {
          return this.sorting.order == 'descending' ? a2['profit'] - a1['profit'] : a1['profit'] - a2['profit'];
        }
        if (this.sorting.column == 'commission') {
          return this.sorting.order == 'descending'
            ? a2['commission'] - a1['commission']
            : a1['commission'] - a2['commission'];
        }
      });
    },
    async downlaodingTradeReport() {
      let promises = [];
      const recordsNum = 500;
      let tradeHistory = [];
      let pageNumber = 1;

      this.progressBarGain = recordsNum > this.totalRecords ? 100 : (recordsNum / this.totalRecords) * 100;

      do {
        let tradeDetail = this.queryAllTradeDetails(pageNumber, recordsNum);
        promises.push(tradeDetail);
        pageNumber++;
      } while (pageNumber < this.totalRecords / recordsNum + 1);

      await Promise.all(promises)
        .then(results => {
          if (results.length > 0) {
            results.map(item => {
              tradeHistory = tradeHistory.concat(item);
            });
          }
        })
        .catch(error => {
          console.error('At least one promise rejected:', error);
        });

      return tradeHistory.map(item => {
        const { account, ticket, platform, symbol, volume, transactionTypeDesc, closeTime, profit, commission } = item;
        return {
          account,
          ticket,
          platform,
          symbol,
          volume,
          transactionTypeDesc,
          closeTime,
          profit,
          commission
        };
      });
    },
    profitSorting(a, b) {
      return a.profit - b.profit;
    },
    queryInstruments() {
      if (!this.dataSourceId) {
        return;
      }

      //恢復預設
      this.instrumentOptions.forEach(i => {
        if (Array.isArray(i.children)) i.children = [];
      });

      apiGet_instruments({ dataSourceId: this.dataSourceId }).then(response => {
        for (let k in response.data) {
          let instrument = this.instrumentOptions.find(i => i.value == k.replace(/\s+/g, ''));
          if (instrument && Array.isArray(instrument.children)) {
            instrument.children = response.data[k].sort().map(prod => {
              return { value: prod, label: prod };
            });
          }
        }
      }, this.$handleError);
    },
    sortChange(column) {
      if (!column.column) {
        return;
      }
      this.sorting = { column: column.column.sortBy, order: column.order };
      this.sortData();
    },
    init() {
      this.queryInstruments();
      this.queryTradeReport(1);
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    dataSourceId() {
      return this.$store.state.behavior.dataSourceId;
    },
    loadingMessage() {
      return this.$t('report.trade.download') + ' ' + this.progressBarPercentage.toFixed(2) + '%';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/rebateReport.scss';
</style>
